import { getClassNames } from '@websolutespa/bom-core';
import { useFocusTrap } from '@websolutespa/bom-mixer-hooks';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { useLlm } from '../../../useLlm/useLlm';
import { useLlmView } from '../../../useLlm/useLlmView';
import { Canvas } from '../Canvas/canvas';
import { Header } from '../Header/header';
import { Intro } from '../Intro/intro';
import { Prompt } from '../Prompt/prompt';
import { Scroller } from '../Scroller/scroller';
import { Thread } from '../Thread/thread';

export const Main = () => {
  const app = useLlm(state => state.app);
  const theme = useLlm(state => state.theme);
  const messages = useLlm(state => state.messages);
  const introed = useLlmView(state => state.introed);
  const visible = useLlmView(state => state.visible);
  const embedded = useLlmView(state => state.embedded);

  const reveal = {
    hidden: {
      opacity: 0,
      scale: .98,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: .3,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: .3,
        delay: .3,
      },
    },
  };

  const intro = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        delay: .6,
        duration: .5,
      },
    },
  };

  const slideIn = (direction: number) => ({
    hidden: {
      y: `${100 * direction}%`,
    },
    show: {
      y: 0,
      transition: {
        type: 'tween',
        delay: app?.contents.customIntro ? 0 : 1.2,
        duration: .6,
      },
    },
    exit: {
      y: `${100 * direction}%`,
      transition: {
        type: 'tween',
        duration: .3,
      },
    },
  });

  const shouldShowPrompt = (!app?.contents.customIntro || introed);

  const trapRef = useRef<HTMLDivElement>(null);
  const [trap, setTrap] = useFocusTrap(trapRef, { disabled: embedded });

  const classNames = getClassNames('llm__main');
  return (
    <motion.div
      key="main-container"
      variants={reveal}
      initial="hidden"
      animate="show"
      exit="exit"
      className={classNames}
      data-lenis-prevent
      ref={trapRef}
    >
      {visible && (
        <Canvas />
      )}
      {!embedded && (
        <motion.div
          className="llm__top"
          variants={slideIn(-1)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <Header />
        </motion.div>
      )}
      <Scroller>
        <motion.div
          variants={intro}
          initial="hidden"
          animate="show"
          className="llm__content">
          <Intro />
          {messages && messages.length > 0 && <Thread />}
        </motion.div>
      </Scroller>
      {shouldShowPrompt && (
        <>
          {app && !theme.canvas.enabled && (
            <div className="llm__underlay" aria-hidden />
          )}
          <motion.main
            className="llm__bottom"
            variants={slideIn(1)}
            initial="hidden"
            animate="show"
            exit="exit"
          >
            <Prompt />
          </motion.main>
        </>
      )}
    </motion.div>
  );
};

